import axios from 'axios';
import { API_URL } from '../config';
import { TOKEN_KEY } from './auth';

const api = axios.create({
  baseURL: API_URL,
});

if (localStorage.getItem(TOKEN_KEY)) {
  api.defaults.headers['Authorization'] = `Bearer ${
    JSON.parse(localStorage.getItem(TOKEN_KEY)).token
  }`;
}

export default api;
