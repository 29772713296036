import { ReactNode } from 'react';

import { Container } from './styles';
import Tooltip from '@material-ui/core/Tooltip';

// icone
import FileCopyIcon from '@material-ui/icons/FileCopy';

// copiar
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

function Copycomponent(props) {
  const { name, data } = props;

  return (
    <Tooltip title="Copiar" placement="right-start">
      <FileCopyIcon
        name="copy"
        onClick={() => {
          copy(data);
          toast.info(`${name} copiado para sua área de transferência`);
        }}
      />
    </Tooltip>
  );
}

export default Copycomponent;
