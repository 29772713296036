import styled from 'styled-components';
// import tagBack from '../../assets/images/icons/desconto_acumulado_icon.png';

export const Container = styled.header`
  z-index: 9;
  position: fixed;
  top: 0;
  left: ${(props) => (props.open ? '240px' : '72px')};
  right: 0;

  transition: all 0.5s;

  display: flex;
  background: rgb(241, 241, 241);
  height: 68px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.12);

  .texts {
    display: flex;
    width: 100%;
    padding: 0 1rem;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 1.25rem;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 0.0075em;
      color: var(--secondary);
    }

    .data {
      display: flex;

      text-align: right;

      font-size: 0.875rem;
      color: var(--primaryLight);
    }
  }
  button {
    cursor: pointer;
    border: 0;
    outline: none;
    background: rgba(var(--black_rgb), 0.05);
    padding: 0 1rem;

    svg {
      color: var(--secondary);
    }

    :hover {
      background: var(--secondary);

      svg {
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 675px) {
    left: 72px;

    .texts {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .data {
        display: none;
      }
    }

    .buttonSignout {
      display: none;
    }
  }
`;
