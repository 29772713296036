import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';

// icones
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';

import { Container } from './styles';
import Copycomponent from '../../../components/Copycomponent';
import Dialogoconfirmacao from '../../../components/Dialogoconfirmacao';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { NewInstanceModal } from '../../../components/NewInstanceModal';

function Instances() {
  const navigate = useNavigate();  
  const [showconf, setShowconf] = useState({
    status: false,
    width: 'sm',
    title: 'Confirmar',
    description: 'Deseja realmente deletar esta instância?',
    btn_conf: 'Sim',
    btn_cancel: 'Não',
    delete: true,
    dados: '',
  });
  const [listIntances, setListInstances] = useState([]);
  const [isNewInstanceModalOpen, setIsNewInstanceModalOpen] = useState(false);


  // funçoes para o modal
  function handleOpenNewInstanceModal(){
    setIsNewInstanceModalOpen(true)
  }

  function handleCloseNewInstanceModal(){
    setIsNewInstanceModalOpen(false)
  }
  
  //listagem de instancias
  async function getInstances(){
    const {data} = await api.get('/instances')
    setListInstances(data)
  }

  function handleDeleteInstance(){
    api.delete(`instances/${showconf.dados.id}`)
    .then(()=> {
      getInstances();
      toast.success('Deletado com sucesso!');
    })
    .catch(err => {
      toast.error('Erro ao deletar');
    })
  }

  useEffect(() => {
    getInstances();
  }, [])
  
  return (
    <Container>
      <Breadcrumbs />
      <div className="page">
        <div className="head">
          <div>
          <h2>Minhas instâncias</h2>
          <p>Instâncias em execução</p>
          </div>
          <button onClick={handleOpenNewInstanceModal}>
            Nova Instância
          </button>
        </div>
        <div className="div__deck">
          <div className="div__card_head">
            <div style={{ flex: 2 }}>Nome</div>
            <div style={{ flex: 3 }}>Key</div>
            <div style={{ flex: 1 }}>Status</div>
            <div style={{ flex: 1 }}>Pagamento</div>
            <div style={{ flex: 1 }}>Opções</div>
          </div>
          {listIntances.map((instance, idx) => (
            <div className="div__card">
              <div style={{ flex: 2 }}>{instance.name}</div>
              <div style={{ flex: 3 }}>
                <Copycomponent name="Key" data={instance?.key} />

                {instance.key}
              </div>
              <div style={{ flex: 1 }}>{instance.status}</div>
              <div style={{ flex: 1 }}>{instance.paid_at}</div>
              <div style={{ flex: 1 }} className="div__options">
                <VisibilityIcon
                  onClick={() => navigate(`/instancias/${instance.id}`)}
                />

                <DeleteIcon
                  name="delete"
                  onClick={() => {
                    setShowconf({
                      ...showconf,
                      status: true,
                      dados: instance,
                    });
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <NewInstanceModal 
      isOpen={isNewInstanceModalOpen}
      onRequestClose={handleCloseNewInstanceModal}
      getInstances={getInstances}
      />

      {showconf.status && (
        <Dialogoconfirmacao
          status={showconf.status}
          width={showconf.width}
          delete={showconf.delete}
          description={showconf.description}
          fechar={() =>
            setShowconf({
              status: false,
              width: 'sm',
              title: 'Confirmar',
              description: 'Deseja realmente deletar esta instância?',
              btn_conf: 'Sim',
              btn_cancel: 'Não',
              delete: true,
              dados: '',
            })
          }
          confirmar={handleDeleteInstance}
        />
      )}
    </Container>
  );
}

export default Instances;
