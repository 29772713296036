import { Routes, Route, Navigate } from 'react-router-dom';

//contexts
import { useAuth } from './context/AuthContext';

// Layout Page System
import { Layout } from './Layout';

// public page
import Login from './pages/public/Login';
// import Cadastro from './pages/public/Cadastro';
// import ConfirmacaoEmail from './pages/public/ConfirmacaoEmail';

// private page
import Instances from './pages/private/Instances';
import Viewinstance from './pages/private/Viewinstance';
import Settings from './pages/private/Settings';
// import Receipts from './pages/private/Receipts';
// import Users from './pages/private/Users';
// import Orcamento from './pages/private/Orcamento';
// import Novocredito from './pages/private/Novocredito';

function PublicPage({ component: Component }) {
  const auth = useAuth();

  return auth.user ? <Navigate to="/instancias" /> : <Component />;
}

const RoutesApp = () => (
  <Routes>
    <Route exact path="/" element={<Navigate to="/login" replace />} />
    <Route path="/login" element={<PublicPage component={Login} />} />
    {/* <Route path="/cadastro" element={<PublicPage component={Cadastro} />} /> */}
    {/* <Route exact path="/confirmacao-cadastro" element={<ConfirmacaoEmail />} /> */}
    {/* <Route exact path="/consumirapi" element={<Consumirapi />} /> */}

    <Route element={<Layout />}>
      <Route exact path="/instancias" element={<Instances />} />
      <Route exact path="/instancias/:instance_id" element={<Viewinstance />} />
      <Route path="/configuracoes" element={<Settings />} />
      {/* <Route path="/novoprojeto" element={<Newproject />} /> */}
      {/* <Route path="/comprovantes" element={<Receipts />} /> */}
      {/* <Route path="/novocredito" element={<Novocredito />} /> */}
      {/* <Route path="/usuarios" element={<Users />} /> */}
    </Route>

    {/* <Route path={'*'} children={<Login />} /> */}
  </Routes>
);

export { RoutesApp };
