import React, { useState } from 'react';

import { Container } from './styles';

import { Outlet } from 'react-router-dom';

import Menu from '../components/Menu';
import Header from '../components/Header';

function Layout() {
  const [open, setOpen] = useState(window.innerWidth > 675 ? true : false);

  const handleChangeMenu = () => {
    setOpen(!open);
  };

  return (
    <Container>
      <Menu open={open} handleChangeMenu={handleChangeMenu} />
      <div className="conteudo">
        <Header open={open} />
        <Outlet />
      </div>
    </Container>
  );
}

export { Layout };
