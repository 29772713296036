// api's de teste
// export const API_URL = 'https://api-tellar.luandeveloper.online';
// export const API_URL = 'http://192.168.0.145:3333';

// api principal
export const API_URL = process.env.REACT_APP_URL_API;
export const WEB_SOCKET = process.env.REACT_APP_WEB_SOCKET;

// configurações de sistema
export const SITE_NAME = 'ZAP Ágil';
