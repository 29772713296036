import Modal from 'react-modal';
import {Container} from './styles'
import { useAuth } from '../../context/AuthContext';
import api from '../../services/api'
import { useState } from 'react';
import {toast} from 'react-toastify'
export function NewInstanceModal(props) {
  const { user } = useAuth();
  const [nameInstance, setNameInstance] = useState('');
  const [nameClient, setNameClient] = useState('');


  function onSubmit(e){
    e.preventDefault();

    const data = {
      user_id: user.id,
      name: nameInstance,
      client_name: nameClient
    };

    api.post('/instances',data)
    .then(()=> {  
      props.getInstances();
      toast.success('Instância cadastrada com sucesso!');
    })
    .catch(error => {
      console.log('error',error.response.data);
      toast.error(error.response.data.error);
    })

    
    props.onRequestClose();

  }

  return (
    <Modal
    isOpen={props.isOpen}
    onRequestClose={props.onRequestClose}
    overlayClassName='react-modal-overlay'
    className='react-modal-content'
    >
      <Container>
        <h2>Criar nova instância</h2>
        <input placeholder='Nome da Instância' onChange={e=> setNameInstance(e.target.value)}/>
        <input placeholder='Nome do cliente' onChange={e=> setNameClient(e.target.value)} />
        <button type='submit' onClick={onSubmit}>Criar</button>
      </Container>
    </Modal>
  )
}
