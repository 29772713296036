import { useState } from 'react';

import { Container } from './styles';

// icones
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

// imagens
import Logoimage from '../../../assets/images/logo-01.png';
import { toast } from 'react-toastify';

// contexto
import { useAuth } from '../../../context/AuthContext';

function Login() {
  const { signin } = useAuth();

  const [dados, setDados] = useState({
    email: '',
    password: '',
    remember: false,
  });

  const handleLogin = (event) => {
    event.preventDefault();

    // para produção
    const requestBody = {
      email: dados.email,
      password: dados.password,
    };

    signin(requestBody, dados.remember);
  };

  const handleInput = (event) => {
    setDados({ ...dados, [event.target.name]: event.target.value });
  };

  return (
    <Container>
      <div className="div__body">
        <div className="div__part1">
          <form onSubmit={handleLogin}>
            <img src={Logoimage} alt="Logo Zap Agil" />
            <h2>Sistema do Usuário</h2>
            {/* <p>ZAP Ágil</p> */}
            <label className="label__out">
              {' '}
              <AlternateEmailIcon />
              <input
                type="email"
                name="email"
                placeholder="E-mail"
                value={dados.email}
                onChange={handleInput}
                required
                autoFocus
              />
            </label>
            <label className="label__out">
              {' '}
              <VpnKeyIcon />
              <input
                type="password"
                name="password"
                placeholder="Senha"
                value={dados.password}
                onChange={handleInput}
                required
              />
            </label>
            <label className="label__remember">
              <input
                type="checkbox"
                checked={dados.remember}
                onChange={() =>
                  setDados({ ...dados, remember: !dados.remember })
                }
              />
              Lembrar senha
            </label>
            <button type="submit">Login</button>
            <div className="div__footer">
              <p>
                Ainda não tem uma conta? <br /> Cadastre-se e comece agora
                mesmo.
              </p>
              <button
                type="button"
                className="btn__register"
                onClick={() => toast.warn('Indisponível no momento')}
              >
                Cadastrar-se
              </button>
              <p>&copy; Desenvolvido por UX Software</p>
            </div>
          </form>
        </div>
        <div className="div__part2"></div>
      </div>
    </Container>
  );
}

export default Login;
