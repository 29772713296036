import styled from 'styled-components';
// background
import BGImage from '../../../assets/images/bg-image.png';
import BGImage01 from '../../../assets/images/image-bg-1.jpg';

export const Container = styled.div`
  /* height: ${window.innerHeight}px; */
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url(${BGImage});
  background-size: 110px;

  .div__body {
    display: flex;
    flex-direction: row;
    height: 100%;

    div {
      flex: 1;
      height: 100%;
    }
    .div__part1 {
      display: flex;
      flex: 2;
      justify-content: center;
      align-items: center;
      form {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        /* background-color: white; */
        width: 350px;
        padding: 2rem 3rem;
        /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
        h2 {
          font-weight: 500;
          margin: 0rem;
          color: var(--primary);
        }
        label {
          flex: 1;
        }

        .label__out {
          border-radius: 2rem;
          background-color: var(--lightgray);
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            margin: 0rem 0.5rem;
            margin-left: 1rem;
            color: var(--gray);
          }
        }
        input {
          border-radius: 2rem;
          flex: 1;
          padding: 0.75rem;
          font-size: 1rem;
          background-color: var(--lightgray);
          border: 0rem;
          outline: none;
          outline-color: var(--primaryLight);
        }
        button {
          border: 0rem;
          padding: 0.75rem;
          border-radius: 2rem;
          cursor: pointer;
          background: var(--primaryLight);
          color: white;
          :hover {
            /* background: var(--secondary); */
            filter: brightness(1.5);
          }
        }

        .label__remember {
          display: flex;
          text-align: left;
          font-size: 0.85rem;
          input[type='checkbox'] {
            flex: initial;
          }
        }
        .div__footer {
          border-top: 2px dotted var(--lightgray);
          margin-top: 1rem;
          p {
            font-size: 0.75rem;
          }
          .btn__register {
            width: 100%;
            background: white;
            border: 1px solid var(--secondary);
            color: var(--secondary);
            :hover {
              background: var(--secondary);
              color: white;
            }
          }
        }
      }
    }
    .div__part2 {
      flex: 2;
      background-image: url(${BGImage01});
      background-size: cover;
      background-position: center;
    }
  }
  @media only screen and (max-width: 675px) {
    .div__body {
      flex-direction: column;
      .div__part1 {
        form {
          flex: 1;
          width: auto;
          min-width: 0px;

          .label__out {
            border-radius: 2rem;
            background-color: var(--lightgray);
            display: flex;

            justify-content: center;
            align-items: center;
            svg {
              margin: 0rem 0.5rem;
              margin-left: 1rem;
              color: var(--gray);
            }
          }
          input[type='email'],
          input[type='password'] {
            border-radius: 2rem;
            flex: 1;
            padding: 0.75rem;
            font-size: 1rem;
            background-color: var(--lightgray);
            border: 0rem;
            outline: none;
            width: 100%;
            outline-color: var(--primaryLight);
          }
          input[type='checkbox'] {
            margin: auto 0rem;
            margin-right: 1rem;
          }

          button {
            border: 0rem;
            padding: 0.75rem;
            font-size: 1rem;
            border-radius: 2rem;
            cursor: pointer;
            background: var(--primaryLight);
            color: white;
            :hover {
              /* background: var(--secondary); */
              filter: brightness(1.5);
            }
          }
        }
      }
      .div__part2 {
        display: none;
      }
    }
  }
`;
