import React, { ReactNode } from 'react';

import { Container, Actionbuttons } from './styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Imgtrocarsenha from '../../assets/images/icons/trocar_senha.png';

import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { toast } from 'react-toastify';

import { Conteudo } from './styles';

function Dialogotrocarsenha({ negar, confirmar, loginData }) {
  const regex =
    /^(?=.*[@!#$%^&*()/\\])(?=.*[0-9])(?=.*[a-zA-Z])[@!#$%^&*()/\\a-zA-Z0-9]{8,20}$/;
  const [open, setOpen] = React.useState(true);
  const [show, setShow] = React.useState(false);

  const [dados, setDados] = React.useState({
    password: '',
    confirm_password: '',
    strong_pass_1: false,
    strong_pass_2: false,
  });
  const handleClose = () => {
    setOpen(false);
    negar();
  };

  const validarSenhas = (e) => {
    if (dados.password.length === 0 || dados.confirm_password.length === 0)
      return toast.warn('Preencha corretamente todos os campos indicados');
    if (!dados.strong_pass_1)
      return toast.warn('Senha deve conter entre 8 e 20 caracteres');
    if (!dados.strong_pass_2)
      return toast.warn(
        'Além de letras, a senha deve conter no mínimo um número e um caractere especial'
      );
    if (dados.password !== dados.confirm_password)
      return toast.warn(
        'As senhas informadas não correspondem, tente novamente'
      );

    confirmar(dados.password);
    handleClose();
  };

  return (
    <Dialog
      // maxWidth={maxWidth}
      fullWidth={window.innerWidth < 675 ? true : false}
      open={open}
      onClose={handleClose}
      scroll="body"
      aria-labelledby="max-width-dialog-title"
      style={{
        zIndex: 99999,
      }}
    >
      <DialogTitle
        id="max-width-dialog-title"
        style={{ fontFamily: "'Poppins', sans-serif !important" }}
      >
        Trocar Senha {dados.name && dados.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Conteudo>
            <img src={Imgtrocarsenha} />
            <div className="div__one_row">
              <input
                autoFocus
                type={show ? 'text' : 'password'}
                placeholder="Digite a nova senha"
                value={dados.password}
                onChange={(e) => {
                  let aux1,
                    aux2 = false;

                  if (regex.test(e.target.value)) {
                    aux2 = true;
                  } else {
                    aux2 = false;
                  }

                  if (e.target.value.length > 7 && e.target.value.length < 21) {
                    aux1 = true;
                  } else {
                    aux1 = false;
                  }

                  setDados({
                    ...dados,
                    password: e.target.value,
                    strong_pass_1: aux1,
                    strong_pass_2: aux2,
                  });

                  //console.log(dados);
                }}
              />{' '}
              <button onClick={() => setShow(!show)}>
                {!show ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
              </button>
            </div>
            <div className="div__one_row">
              <input
                type="password"
                placeholder="Confirme a senha"
                value={dados.confirm_password}
                onChange={(a) =>
                  setDados({ ...dados, confirm_password: a.target.value })
                }
              />{' '}
            </div>
            <ul>
              <li
                style={{
                  color: dados.strong_pass_1 && 'var(--success)',
                }}
              >
                Senha deve conter entre 8 e 20 caracteres
              </li>
              <li
                style={{
                  color: dados.strong_pass_2 && 'var(--success)',
                }}
              >
                Para uma senha forte, é aconselhável que contenha números,
                letras e caracteres especiais
              </li>
              <li
                style={{
                  color: 'var(--success)',
                }}
              >
                Sua senha e credenciais de acesso são de uso pessoal, evite
                informar a terceiros
              </li>
            </ul>
          </Conteudo>
        </DialogContentText>
      </DialogContent>
      <Actionbuttons>
        <button type="button" className="btn__setEdit" onClick={() => negar()}>
          Cancelar
        </button>
        <button
          type="input"
          onClick={() => validarSenhas()}
          className="btn__setSalvar"
        >
          Salvar
        </button>
      </Actionbuttons>
    </Dialog>
  );
}

export default Dialogotrocarsenha;
