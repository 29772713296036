import styled from 'styled-components';

export const Container = styled.form`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  h2 {
    color: var(--textcolor);
    font-size: 1.5rem;
    margin-bottom:2rem;
    text-align: center;
  }

  input {  
    flex:1;
    padding: 1rem 2.5rem;
    height:4rem;
    border: 1px solid #d7d7d7;
    background: var(--lightgray);
    border-radius: 0.25rem;
    //font-weight: 400;
    font-size: 1rem;

    & + input {
      margin-top: 1rem;
    }
  }

  button[type="submit"] {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    background: var(--primaryLight);
    color: #fff;
    border-radius:0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1.5rem;
    cursor: pointer;

    transition: filter 0.2s;

    &:hover {
          filter: brightness(0.9);
        }
  }

`;