import React from 'react';

import { Container, Divdesconto } from './styles';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

// contexts
import { useAuth } from '../../context/AuthContext';
import { useUserData } from '../../context/UserDataContext';

// config
import { SITE_NAME } from '../../config';

export default function Header({ open }) {
  const { user, signout } = useAuth();


  return (
    <Container open={open}>
      <div className="texts">
        <h2>{SITE_NAME}</h2>
        <div className="data">
          {user && user.name}
          <br />
          {user && user.email}
        </div>
      </div>
      <button className="buttonSignout" onClick={() => signout()}>
        <ExitToAppIcon />
      </button>
    </Container>
  );
}
