import { useState, useEffect } from 'react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Dialogotrocarsenha from '../../../components/Dialogotrocarsenha';

// icones

import { Container } from './styles';

// user
import { useUserData } from '../../../context/UserDataContext';

import api from '../../../services/api';
import { useAuth } from '../../../context/AuthContext';
import { toast } from 'react-toastify';

function Settings() {
  const { user } = useAuth();
  const [showdialogpass, setShowdialogpass] = useState(false);
  const { loginData } = useUserData();

  const [dados, setDados] = useState({
    email: user.email,
    name: user.name,
  });

  const [dadostemp, setDadostemp] = useState({
    email: '',
    name: '',
  });

  const [keys, setKeys] = useState({
    public_key: user.public_key,
    private_key: user.private_key
  })

  const [edit, setEdit] = useState(false);

  const setEdicao = () => {
    setDadostemp(dados);
    setEdit(true);
  };

  const handleInput = (event) => {
    setDadostemp({ ...dadostemp, [event.target.name]: event.target.value });
  };

  const submitChanges = async (event) => {
    event.preventDefault();
    setDados(dadostemp);
    setEdit(false);
    setDadostemp({
      email: '',
      name: '',
    });
  };

  const handleUpdatePublicKey = async() =>{
    api.patch(`/users/change-public-key/${user.id}`)
    .then((response) => {     
      setKeys({...keys, public_key:response.data.public_key});
      toast.success('Chave pública alterada com sucesso!');
    })
    .catch(error => {
      console.log(error)
      toast.error('Erro ao alterar chave.')
    })
  }

  const handleUpdatePrivateKey = async() =>{
    api.patch(`/users/change-private-key/${user.id}`)
    .then((response) => {      
      setKeys({...keys, private_key:response.data.private_key});
      toast.success('Chave privada alterada com sucesso!')
    })
    .catch(error => {
      console.log(error)
      toast.error('Erro ao alterar chave.')
    })
  }

  const cancelChanges = () => {
    setEdit(false);
    setDadostemp({
      email: '',
      name: '',
    });
  };

  const updatePassword = (password) => {
    api
      .put(`/users/change-password/${user.id}`, { password })
      .then((res) => {
        setShowdialogpass(false);
        toast.success('Senha atualizada com sucesso!');
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        toast.error('Erro ao mudar a senha.');
      });
  };

  function updateInstance() {
    api.put(`users/${user.id}`, { ...user, email: dadostemp.email, name: dadostemp.name })
      .then(() => {
        toast.success('Usuário atualizado com sucesso.');
      })
      .catch(error => {
        console.log(error);
      })
  }


  return (
    <Container>
      <Breadcrumbs />
      <div className="page">
        <div className="head">
          <h2>Configurações</h2>
          <p>Edite suas informações de acesso à plataforma.</p>
        </div>
        <form onSubmit={submitChanges}>
          <div className="div__deck">
            <div className="div__card">
              <div className="div__oneColumn">
                <span>Usuário</span>
                <div className="div__oneColumn">
                  <label>Nome</label>
                  <input
                    name="name"
                    value={edit ? dadostemp.name : dados.name}
                    type="text"
                    disabled={!edit}
                    required
                    className={edit && 'editavel'}
                    onChange={handleInput}
                  />
                </div>
                <div className="div__oneColumn">
                  <label>E-mail</label>
                  <input
                    name="email"
                    value={edit ? dadostemp.email : dados.email}
                    type="email"
                    disabled={!edit}
                    style={{ flex: 1 }}
                    required
                    className={edit && 'editavel'}
                    onChange={handleInput}
                  />
                </div>
                <div
                  className="div__oneColumn"
                  style={{
                    opacity: edit && 0.5,
                    pointerEvents: edit && 'none',
                  }}
                >
                  <label>Senha</label>
                  <span
                    className="span__changePass"
                    onClick={() => setShowdialogpass(true)}
                  >
                    Trocar senha
                  </span>
                </div>
                {edit && (
                  <div className="div__notas">
                    <label>Legenda:</label>
                    <p>
                      <b>•</b> Editável
                    </p>
                  </div>
                )}
                <div className="div__menuButtons">
                  {edit ? (
                    <>
                      <button
                        type="button"
                        className="btn__setEdit"
                        onClick={() => cancelChanges()}
                      >
                        Cancelar
                      </button>
                      <button type="input" className="btn__setSalvar" onClick={updateInstance}>
                        Salvar
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      className="btn__setEdit"
                      onClick={setEdicao}
                    >
                      Editar
                    </button>
                  )}
                </div>
              </div>
              <div className="div_keys">
                <span>Chaves</span>
                <div className="input_key">
                  <label>Chave Pública</label>
                  <input
                    name="public_key"
                    value={keys.public_key}
                    type="text"
                    disabled={true}                 
                  />
                </div>
                <div className="input_key">
                  <label>Chave Privada</label>
                  <input
                    name="private_key"
                    value={keys.private_key}
                    type="text"
                    disabled={true}                    
                  />
                </div>
                <div className="div__menuButtons">
                  <button type="button" className="btn__setEdit" onClick={handleUpdatePublicKey}>
                    Gerar chave pública
                  </button>
                  <button type="button" className="btn__setEdit" onClick={handleUpdatePrivateKey}>
                    Gerar chave privada
                  </button>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>
      {showdialogpass && (
        <Dialogotrocarsenha
          negar={() => setShowdialogpass(false)}
          confirmar={(password) => updatePassword(password)}
          loginData={loginData}
          user={user}
        />
      )}
    </Container>
  );
}

export default Settings;
