import { createContext, useState, useContext, useEffect } from 'react';
import api from '../services/api';
import { TOKEN_KEY } from '../services/auth';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CodeSharp } from '@material-ui/icons';

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // função de login
  const signin = async (newUser, remember) => {
    await api
      .post('/session', newUser)
      .then((response) => {
       // console.log('response',response?.data)
        setUser({ ...response?.data?.user });
        remember &&
          localStorage.setItem(TOKEN_KEY, JSON.stringify({ ...response?.data }));

        api.defaults.headers['Authorization'] = `Bearer ${response?.data.token}`;

        return navigate('/instances');
      })
      .catch((err) => {
        return toast.error(err.response.data.message);
      });
  };

  // função de logout
  const signout = () => {
    setUser(null);
    localStorage.removeItem(TOKEN_KEY);

    return navigate('/login');
  };

  const isAuthenticated = async () => {
    let data =
      localStorage.getItem(TOKEN_KEY) !== null
        ? localStorage.getItem(TOKEN_KEY)
        : false;
    data &&
      (await api
        .post('/validate-token', { token: JSON.parse(data).token })
        .then((res) => {
          console.log('[LOGIN] Login=> ', res.data);
          data && setUser(JSON.parse(data));
        })
        .catch(async (error) => {
          console.log('[ERROR] => ', error.data);
          localStorage.removeItem(TOKEN_KEY);
          signout();
        }));

    !data && (await navigate('/login'));
  };

  let value = { user, signin, signout, isAuthenticated };

  useEffect(() => {
    isAuthenticated();
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const auth = useContext(AuthContext);

  return auth;
}
