import styled from 'styled-components';
// background
import BGImage from '../../../assets/images/bg-image.png';

export const Container = styled.div`
  background: url(${BGImage});
  height: 100%;
  background-size: 110px;
  padding: 1rem;

  .page {
    margin: 0rem;
    background-color: white;
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
    padding: 0.2rem 2rem;
    padding-bottom: 2rem;
    .head {
      /* border-bottom: 1px dotted var(--gray); */
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 0.5rem;
      h2 {
        text-align: left;
        font-weight: 600;
        color: rgba(var(--blackRgb), 0.7);
        margin-bottom: 0rem;
      }
      p {
        text-align: left;
        margin: 0rem;
        font-size: 0.9rem;
        color: var(--textcolor);
      }

      button {
        font-size: 1rem;
        color: #fff;
        background:var(--secondary);
        border: 0;
        padding: 0 2rem;
        height: 3rem;
        border-radius:0.25rem;
        cursor:pointer;
        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.9);
        }
      }
    }

    .div__deck {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      margin-top: 2rem;
      max-width: 100% !important;
      overflow-x: scroll !important;

      .div__card_head {
        display: flex;
        gap: 1rem;
        padding: 0rem 0.5rem;
        min-width: 45rem;
        div {
          padding: 0rem 0.5rem;
          text-align: left;
          font-size: 0.9rem;
          font-weight: 500;
          color: var(--primaryLight);
        }
      }
      .div__card {
        border: 1px solid rgba(var(--blackRgb), 0.05);
        border-radius: 0.25rem;
        box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
        display: flex;
        gap: 1rem;
        padding: 0rem 0.5rem;
        min-width: 45rem;
        div {
          padding: 0.75rem 0.5rem;
          text-align: left;
          font-size: 0.9rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          svg[name='copy'] {
            font-size: 1rem;
            margin-bottom: -0.1rem;
            margin-right: 0.5rem;

            cursor: pointer;
            :hover {
              color: var(--secondary);
            }
          }
        }
        .div__options {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          svg {
            color: var(--gray);
            cursor: pointer;
            :hover {
              color: var(--secondary);
            }
          }
          svg[name='delete'] {
            :hover {
              color: var(--delete);
            }
          }
        }
      }
    }
  }
`;
