import styled from 'styled-components';

export const Container = styled.div`
  .breadcrums {
    text-align: left;
    display: flex;
    margin-top: 2rem;
    font-size: 0.85rem;
    a {
      text-decoration: none;
      margin: 0rem 0.1rem;
      color: var(--gray);
      :hover {
        color: var(--secondary);
      }
    }
    svg {
      font-size: 1.1rem;
      margin-right: -0.2rem;
    }
  }
`;
