import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Copycomponent from '../../../components/Copycomponent';

import api from '../../../services/api';

// icones

import { Container } from './styles';

import Qrcodestatus from '../../../components/Qrcodestatus';

function Viewinstance() {
  let { instance_id } = useParams();

  const [dados, setDados] = useState();


  async function getInstance(){
    const {data} = await api.get(`/instances/${instance_id}`)
    setDados(data)
  }

  // useEffect(() => {
  //   setDados(listinstances.find((a) => Number(a.id) === Number(instance_id)));
  // }, [instance_id, listinstances]);

  useEffect(() => {
    getInstance();
  },[])

  return (
    <Container>
      <Breadcrumbs />
      <div className="page">
        <div className="head">
          <h2>Instância: {dados?.name}</h2>
          <p>Instâncias em execução</p>
        </div>
        <div className="div__deck">
          <div className="div__card_head">
            {/* <div style={{ flex: 2 }}>Nome</div>
            <div style={{ flex: 3 }}>Token</div>
            <div style={{ flex: 1 }}>Status</div>
            <div style={{ flex: 1 }}>Pagamento</div>
            <div style={{ flex: 1 }}>Opções</div> */}
          </div>

          <div className="div__card">
            <div style={{ flex: 2 }}>
              <div className="div__oneColumn">
                <label>
                  Nome <Copycomponent name="Nome" data={dados?.name} />
                </label>
                <input type="text" value={dados?.name} disabled />
              </div>
              <div className="div__oneColumn">
                <label>
                  Key <Copycomponent name="Key" data={dados?.key} />
                </label>
                <input type="text" value={dados?.key} disabled />
              </div>
              <div className="div__oneRow">
                <div className="div__oneColumn">
                  <label>Criação </label>
                  <input
                    type="text"
                    value={new Date(dados?.created_at).toLocaleString('pt-BR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                    disabled
                  />
                </div>
                <div className="div__oneColumn">
                  <label>
                    Último pagamento{' '}
                    {/* <Copycomponent
                      name="Data do último pagamento"
                      data={new Date(dados?.paid_at).toLocaleString('pt-BR', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    /> */}
                  </label>
                  <input
                    type="text"
                    value={ dados?.paid_at ? new Date(dados?.paid_at).toLocaleString('pt-BR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    }) : null}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="field_qrcode_out" style={{ flex: 3 }}>
              <div className="div__top_field">
                {dados?.key && <Qrcodestatus token={dados?.key} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Viewinstance;
