import styled from 'styled-components';

export const Conteudo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif !important;

  img {
    max-width: 100px;
    margin: 2rem;
  }
  .div__one_row {
    /* padding: 1rem 1rem; */
    max-width: 20rem;
    border: 1px solid var(--lightgray);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    width: 100%;
    display: flex;
    align-items: center;
    margin: 0.25rem 0rem;
    :visited {
      outline: 5px dotted var(--secondary);
    }
    input {
      padding: 0.75rem 1rem;
      width: 100%;
      margin: 0rem;
      border: 0rem;
      outline-color: rgba(0, 0, 0, 0) !important;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0rem;
      background-color: transparent;
      padding: 0rem 1rem;
      cursor: pointer;
      svg {
        font-size: 2rem;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  ul {
    margin: 1rem 2rem;
    /* background: red; */
    font-size: 0.85rem;
  }
  @media screen and (max-width: 675px) {
    ul {
      margin: 1rem 1rem;
      padding: 0rem;
      /* background: red; */
      font-size: 0.85rem;
    }
  }
`;

export const Actionbuttons = styled.div`
  text-align: right;
  margin-right: 1rem;
  margin-bottom: 1rem;
  .btn__setSalvar {
    padding: 0.5rem 1.25rem;
    border-radius: 2rem;
    border: 1px solid var(--secondary);
    background: var(--secondary);
    color: var(--white);
    margin: 0.25rem;
    cursor: pointer;
    opacity: 0.85;
    :hover {
      box-shadow: 0px 5px 5px rgba(var(--blackRgb), 0.1);
      opacity: 1;
      /* background: var(--white); */
      /* color: var(--secondary); */
    }
  }
  .btn__setEdit {
    padding: 0.5rem 1.25rem;
    border-radius: 2rem;
    border: 1px solid var(--lightgray);
    color: var(--gray);
    margin: auto;
    cursor: pointer;
    margin: 0.25rem;
    margin-left: 0rem;
    :hover {
      box-shadow: 0px 5px 5px rgba(var(--blackRgb), 0.1);
      color: var(--primaryLight);
    }
  }
`;
