import { ReactNode, useState, useEffect } from 'react';

import { Container } from './styles';

// icones
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

// conexão via web sockect
import { WEB_SOCKET } from '../../config';
import io from 'socket.io-client';
import { toast } from 'react-toastify';


function Qrcodestatus(props) {
  const socket = io(WEB_SOCKET);
  socket.on('connect', () => console.log('[IO] Connect => New connection'));
  const { token } = props;
  const [dados, setDados] = useState({
    session_name: '',
    status_connection: '',
  });
  const [statusconnection, setStatusconnection] = useState('Aguarde...');
  const [showcode, setShowcode] = useState(true);

  const [tempdata, setTempdata] = useState('');
  const [datamsg, setDatamsg] = useState({
    number: '',
    message: '',
  });

  const [qrimage, setQrimage] = useState();

  useEffect(() => {
    if (token) {
      setDados({ ...dados, session_name: token });
      socket.emit('dados', { session_name: token });
    }

    socket.on('dados', (res) => {
      console.log(res);
      if (res?.status_connection) {
        switch (res.status_connection) {
          case 'notLogged':
            setStatusconnection('Não logado');
            break;
          case 'isLogged':
            setStatusconnection('Logado');
            break;
          case 'qrReadSuccess':
            setStatusconnection('Logado');
            break;
          case 'browserClose':
            setStatusconnection('Falha na autentificação');
            break;
          case 'qrReadFail':
            setStatusconnection('Falha na autentificação');
            break;
          case 'autocloseCalled':
            setStatusconnection('Falha na autentificação');
            break;
          case 'desconnectedMobile':
            setStatusconnection('Celular desconectado');
            break;
          case 'deleteToken':
            setStatusconnection('Token deletado');
            break;
          default:
            setStatusconnection('Aguarde...');
        }
      }
      if (res?.change_image) {
        setQrimage(res.change_image);
        setShowcode(true);
        // setShowcode(false);
        // setTimeout(() => setShowcode(true), 100);
        // console.log('Imagem', res.change_image);
      }
      if (res?.sendMessage) {
        if (res.sendMessage === 'error') {
          toast.error('Erro ao enviar mensagem');
        } else {
          toast.success('Mensagem enviada com sucesso');
          setDatamsg({ number: '', message: '' });
        }
      }
      if (res?.attempts) {
        if (res?.attempts > 3) {
          socket.disconnect();
        }
      }
    });
  }, []);

  const Descriptionstatus = (status) => {
    switch (status) {
      case 'Não logado':
        return 'Escaneie o código abaixo para utilizar sua instãncia';
        break;
      case 'Logado':
        return 'Sua instância está pronta para uso';
        break;
      case 'Aguarde...':
        return '...';
        break;
      case 'Falha na autentificação':
        return 'Reinicie a página e tente novamente';
        break;
      case 'Celular desconectado':
        return 'Reinicie a página e tente novamente';
        break;
      case 'Token deletado':
        return 'Reinicie a página e tente novamente';
        break;

      default:
        return '...';
    }
  };
  console.log('status: ', statusconnection)
  return (
    <Container>
      <h2>
        <b>STATUS:</b> {statusconnection}
      </h2>
      <p>
        <b>Descrição:</b> {Descriptionstatus(statusconnection)}
      </p>

      {statusconnection !== 'Logado' &&
        statusconnection !== 'Aguarde...' &&
        dados.session_name &&
        showcode && (
          <div className="div__field_image">
            <img src={qrimage} alt="QR Code" />
          </div>
        )}
      {
        statusconnection === 'Celular desconectado' &&
        dados.session_name &&
        showcode && (
          <div className="div__field_image">
            <img src={qrimage} alt="QR Code" />
          </div>
        )
      }
      {statusconnection === 'Logado' && (
        <div className="div__field_image_ok">
          <CheckCircleOutlineIcon />
        </div>
      )}
    </Container>
  );
}

export default Qrcodestatus;
