import { ReactNode, useEffect, useState } from 'react';

import { Container } from './styles';

// icones
import HomeIcon from '@material-ui/icons/Home';

// link
import { Link } from 'react-router-dom';

function Breadcrumbs() {
  const [rotas, setRotas] = useState([]);

  useEffect(() => {
    setRotas(window.location.pathname.split('/'));
  }, []);

  var historico = '';

  return (
    <Container>
      <div className="breadcrums">
        <Link to={'../'.repeat(rotas.length)}>
          <HomeIcon />
        </Link>
        {rotas.map((a, idx) => {
          if (idx > 0) historico += '/' + a;
          return <Link to={'../' + historico}>{a}/</Link>;
        })}
      </div>
    </Container>
  );
}

export default Breadcrumbs;
