import styled from 'styled-components';

export const Container = styled.div`
  svg[name='copy'] {
    font-size: 1rem;
    margin-bottom: -0.1rem;
    margin-right: 0.5rem;
    color: var(--gray);

    cursor: pointer;
    :hover {
      color: var(--secondary);
    }
  }
`;
