import { createContext, useState, useContext, useEffect } from 'react';
import api from '../services/api';

import { useAuth } from './AuthContext';

const UserDataContext = createContext(null);

export function UserDataProvider({ children }) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

 // const [loginData, setLoginData] = useState(null);

  useEffect(() => {
    user && getDadosUsuario();
  }, [user]);

  const getDadosUsuario =  () => {
    //console.log('user', user)
    api
      .get(
        `users/${user.id}`
      )
      .then((res) => {
        const data = res?.data;
        //console.log('user data', data)
        //setLoginData(data.login_data);

        setLoading(false);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };

  // let value = {
  //   loginData,
  //   loading,
  // };

  return (
    <UserDataContext.Provider value={user}>
      {children}
    </UserDataContext.Provider>
  );
}

export function useUserData() {
  return useContext(UserDataContext);
}
