import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 1.2rem;
    font-weight: 400;
    b {
      color: var(--textcolor);
    }
    margin: 0rem;
  }
  p {
    margin: 0rem;
    color: var(--textcolor);
  }
  .div__field_image_ok {
    background: rgba(var(--primaryLightRgb), 0.1);
    margin: auto;
    margin-top: 2rem;
    width: 265px;
    height: 265px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    svg {
      font-size: 10rem;
      color: var(--success);
    }
  }
  .div__field_image {
    margin: auto;
    margin-top: 2rem;
    width: 265px;
    height: 265px;

    padding: 0rem;
    img {
      margin: auto;

      width: 264px;
      height: auto;
    }
  }
`;
