import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Container, FielButtons } from './styles';

export default function Dialogoconfirmacao(props) {
  const [open, setOpen] = React.useState(props.status ? props.status : true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={props.width ? props.width : 'sm'}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 9999999999 }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontFamily: "'Poppins', sans-serif",
            color: 'var(--primary)',
          }}
        >
          {props.title ? props.title : 'Confirmar'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontFamily: "'Poppins', sans-serif" }}
          >
            {props.description
              ? props.description
              : 'Deseja confirmar sua ação?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons delete={props.delete}>
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
            >
              {props.btn_cancel ? props.btn_cancel : 'Não'}
            </button>
            <button
              onClick={() => {
                props.confirmar();
                handleClose();
              }}
              className="btn__register"
            >
              {props.btn_conf ? props.btn_conf : 'Sim'}
            </button>
          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
