import React from 'react';

import { Container } from './styles';
import { Link, useResolvedPath, useMatch } from 'react-router-dom';

// icons
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PostAddIcon from '@material-ui/icons/PostAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import PeopleIcon from '@material-ui/icons/People';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MenuIcon from '@material-ui/icons/Menu';
import ReceiptIcon from '@material-ui/icons/Receipt';

// context
import { useUserData } from '../../context/UserDataContext';
import { useAuth } from '../../context/AuthContext';

// imagens
import Logoimage from '../../assets/images/logo-01.png';

const rotas = [
  {
    path: '/instancias',
    Icon: <FormatListBulletedIcon />,
    title: 'Minhas instâncias',
  },

  {
    path: '/configuracoes',
    Icon: <SettingsIcon />,
    title: 'Configurações',
  },
];

function CustomLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });

  return (
    <div>
      <Link className={`${match ? 'active' : ''}`} to={to} {...props}>
        {children}
      </Link>
    </div>
  );
}

export default function Menu({ open, handleChangeMenu }) {
  
  const { signout } = useAuth();

  const handleChangeMenuIsMobile = () => {
    if (window.innerWidth <= 600) {
      handleChangeMenu();
    }
  };

  return (
    <Container open={open}>
      <button className="seta">
        <div className="containerImg2"></div>
        {open ? (
          <ChevronLeftIcon onClick={() => handleChangeMenu()} />
        ) : (
          <MenuIcon onClick={() => handleChangeMenu()} />
        )}
      </button>

      <div className="contentTexts">
        <p>Seja bem-vindo</p>
       

        <button onClick={signout}>Sair</button>
      </div>

      <div className="containerImg">
        <img src={Logoimage} alt="Logo" />
      </div>

      <h4>Menu</h4>
      <nav>
        {rotas.map((rota) => (
          <CustomLink to={rota.path} onClick={() => handleChangeMenuIsMobile()}>
            {rota.Icon}
            <p>{rota.title}</p>
          </CustomLink>
        ))}
      </nav>
    </Container>
  );
}
